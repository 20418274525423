@font-face {
  font-family: 'Open Sans';
  src: url('/fonts/OpenSans-Regular.ttf');
}

.hideHorizontalScroll {
  overflow-x: hidden !important;
}

#root {
  min-width: 824px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
